import Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';
import LoginForm, {
    LoginEvents,
    LoginResponseType
} from '@naturehouse/design-system/components/organisms/login-form/LoginForm';
import LoginManager from './LoginManager';

export enum LoginState {
    USER_AVAILABLE = 'userAvailable',
    IS_LANDLORD = 'userIsLandlord'
}

export interface LoginDialogInterface {
    show(): void;
    close(): void;
    successCallback: (response: LoginResponseType) => void;
}

export default class LoginDialog implements LoginDialogInterface {
    static #instance: LoginDialog | null = null;

    static getInstance(): LoginDialog {
        if (this.#instance === null) {
            this.#instance = new LoginDialog();
        }

        return this.#instance;
    }

    static destroyInstance(): void {
        this.#instance = null;
    }

    static destroy(): void {
        this.#instance = null;
    }

    readonly #dialog: Dialog;

    readonly #form: LoginForm;

    constructor() {
        this.#dialog = document.getElementById('login-dialog') as Dialog;
        this.#form = this.#dialog?.querySelector('[is="nh-login-form"]') as LoginForm;

        if (this.#dialog === null || this.#form === null) {
            return;
        }

        this.#form.addEventListener(LoginEvents.LOGIN_SUCCESS, this.#handleSuccess);
    }

    public show(): void {
        this.#dialog.showModal();
    }

    public close(): void {
        this.#dialog.close();
        LoginDialog.destroy();
    }

    public successCallback = (response: LoginResponseType): void => {
        if (response.isLandlord) {
            this.#redirectLandlord();
            return;
        }

        this.#redirectUser();
    };

    readonly #handleSuccess = (e: Event): void => {
        const event = e as CustomEvent;
        document.body.classList.add(LoginState.USER_AVAILABLE);

        if (event.detail.isLandlord ?? false) {
            document.body.classList.add(LoginState.IS_LANDLORD);
        }

        this.successCallback(event.detail);

        const loginManager = LoginManager.getInstance();
        loginManager.isLoggedIn = true;

        this.#dialog.close();
    };

    readonly #redirectUser = (): void => {
        const redirectURL = this.#dialog.dataset.userRedirect;
        if (!redirectURL) {
            return;
        }

        window.location.href = redirectURL;
    };

    readonly #redirectLandlord = (): void => {
        const redirectURL = this.#dialog.dataset.landlordRedirect;
        if (!redirectURL) {
            return;
        }

        window.location.href = redirectURL;
    };
}
