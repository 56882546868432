import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import HttpClient from '@naturehouse/nh-essentials/lib/requests/HttpClient';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';

export enum CollectionEvents {
    ITEM_ADDED = 'collection-event-added',
    ITEM_DELETED = 'collection-event-deleted',
    ITEM_UPDATED = 'collection-event-updated',
    ITEM_RETRIEVED = 'collection-event-retrieved'
}

export default abstract class CollectionBase<
    T,
    PT = Record<string, string>
> extends AbstractSubject {
    public async add<RT>(data: T, params?: PT): Promise<RT | void> {
        const endpoint = this.endpoint(params);

        let returnValue: RT | undefined;

        try {
            const result = (await HttpClient.post(
                endpoint,
                JSON.stringify(data)
            )) as unknown as AjaxResponse<RT>;

            if (result instanceof HttpError) {
                throw result;
            }

            const event = new CustomEvent(CollectionEvents.ITEM_ADDED, {
                detail: {
                    type: this.constructor.name,
                    data: result.data ?? {}
                }
            });

            this.notify(event);

            returnValue = result.data;
        } catch (e: unknown) {
            const error = e as HttpError<AjaxResponseError>;
            this.onError(error);
        }

        return returnValue;
    }

    public async update<RT>(data: T, params?: PT): Promise<RT | void> {
        const endpoint = this.updateEndpoint(params);

        let returnValue: RT | undefined;

        try {
            const result = (await HttpClient.put(
                endpoint,
                JSON.stringify(data)
            )) as unknown as AjaxResponse<RT>;

            if (result instanceof HttpError) {
                throw result;
            }

            const event = new CustomEvent(CollectionEvents.ITEM_UPDATED, {
                detail: {
                    type: this.constructor.name,
                    data: result.data
                }
            });

            this.notify(event);

            returnValue = result.data;
        } catch (e: unknown) {
            const error = e as HttpError<AjaxResponseError>;
            this.onError(error);
        }

        return returnValue;
    }

    public async delete(params?: PT): Promise<void> {
        const endpoint = this.updateEndpoint(params);

        try {
            const result = (await HttpClient.delete(endpoint)) as unknown as AjaxResponse;

            if (result instanceof HttpError) {
                throw result;
            }

            const event = new CustomEvent(CollectionEvents.ITEM_DELETED, {
                detail: {
                    type: this.constructor.name,
                    data: result?.data ?? {}
                }
            });

            this.notify(event);
        } catch (e: unknown) {
            const error = e as HttpError<AjaxResponseError>;
            this.onError(error);
        }
    }

    public async retrieve<RT>(params?: PT): Promise<RT | void> {
        const endpoint = this.endpoint(params);

        let returnValue: RT | undefined;

        try {
            const result = (await HttpClient.get(endpoint)) as unknown as AjaxResponse<RT>;

            if (result instanceof HttpError) {
                throw result;
            }

            const event = new CustomEvent(CollectionEvents.ITEM_RETRIEVED, {
                detail: {
                    type: this.constructor.name,
                    data: result.data
                }
            });

            this.notify(event);

            returnValue = result.data;
        } catch (e: unknown) {
            const error = e as HttpError<AjaxResponseError>;
            this.onError(error);
        }

        return returnValue;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected endpoint(params?: PT): string {
        throw new UnavailableMethodException();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected updateEndpoint(params?: PT): string {
        throw new UnavailableMethodException();
    }

    protected abstract onError(error: HttpError<AjaxResponseError>): void;
}
